export const companiesUsingFable = [
  {
    source: 'https://www.sharefable.com/companies/hubilo-logo.svg',
    companyName: 'Hubilo',
  },
  {
    source: 'https://www.sharefable.com/companies/recruiter-flow-logo.svg',
    companyName: 'Recruiterflow',
  },
  {
    source: 'https://www.sharefable.com/companies/StartupDefense.svg',
    companyName: 'Startup Defense',
  },
  {
    source: 'https://www.sharefable.com/companies/Upwell.svg',
    companyName: 'Upwell',
  },
  {
    source: 'https://www.sharefable.com/companies/DataDriven.svg',
    companyName: 'Data Driven',
  },
  {
    source: 'https://www.sharefable.com/companies/Flowla.svg',
    companyName: 'Flowla',
  },
  {
    source: 'https://www.sharefable.com/companies/LuckyOrange.svg',
    companyName: 'Lucky Orange',
  },
  {
    source: 'https://www.sharefable.com/companies/Mayan.svg',
    companyName: 'Mayan',
  },
  {
    source: 'https://www.sharefable.com/companies/Recurpost.svg',
    companyName: 'Recurpost',
  },
  {
    source: 'https://www.sharefable.com/companies/riyalto-logo.svg',
    companyName: 'Riyalto',
  },
  {
    source: 'https://www.sharefable.com/companies/superhire-logo.svg',
    companyName: 'Superhire',
  },
  {
    source: 'https://www.sharefable.com/companies/Parserr.svg',
    companyName: 'Parserr',
  },
  {
    source: 'https://www.sharefable.com/companies/Zoftware.svg',
    companyName: 'Zoftware',
  },
  {
    source: 'https://www.sharefable.com/companies/Flipcx.svg',
    companyName: 'Flipcx',
  },
  {
    source: 'https://www.sharefable.com/companies/LetsDive.svg',
    companyName: 'Lets Dive',
  },
  {
    source: 'https://www.sharefable.com/companies/Smartbite.svg',
    companyName: 'Smartbite',
  },
  {
    source: 'https://www.sharefable.com/companies/Xobin.svg',
    companyName: 'Xobin',
  },
  {
    source: 'https://www.sharefable.com/companies/PuzzleApp.svg',
    companyName: 'Puzzle App',
  },
  {
    source: 'https://www.sharefable.com/companies/1Page.svg',
    companyName: '1Page',
  },
  {
    source: 'https://www.sharefable.com/companies/Acoustic.svg',
    companyName: 'Acoustic',
  },
  {
    source: 'https://www.sharefable.com/companies/ContextQA.svg',
    companyName: 'Context QA',
  },
  {
    source: 'https://www.sharefable.com/companies/Flowmo.svg',
    companyName: 'Flowmo',
  },
  {
    source: 'https://www.sharefable.com/companies/Audiocodes.svg',
    companyName: 'Audiocodes',
  },
  {
    source: 'https://www.sharefable.com/companies/Tan-90.svg',
    companyName: 'Tan 90',
  },
  {
    source: 'https://www.sharefable.com/companies/Reditus.svg',
    companyName: 'Reditus',
  },
  {
    source: 'https://www.sharefable.com/companies/Adaface.svg',
    companyName: 'Adaface',
  },
  {
    source: 'https://www.sharefable.com/companies/WeCP.svg',
    companyName: 'WeCP',
  },
  {
    source: 'https://www.sharefable.com/companies/Zluri.svg',
    companyName: 'Zluri',
  },
  {
    source: 'https://www.sharefable.com/companies/Wisemonk.svg',
    companyName: 'Wisemonk',
  },
  {
    source: 'https://www.sharefable.com/companies/VisualK.svg',
    companyName: 'VisualK',
  },
  {
    source: 'https://www.sharefable.com/companies/EventHQ.svg',
    companyName: 'EventHQ',
  },
  {
    source: 'https://www.sharefable.com/companies/Aize.svg',
    companyName: 'Aize',
  },
  {
    source: 'https://www.sharefable.com/companies/Paytm.svg',
    companyName: 'Paytm',
  },
  {
    source:
      'https://www.sharefable.com/companies/OnlineBusinessAutomator.svg',
    companyName: 'Online Business Automator',
  },
  {
    source: 'https://www.sharefable.com/companies/Intellipaat.svg',
    companyName: 'Intellipaat',
  },
  {
    source: 'https://www.sharefable.com/companies/Avon.svg',
    companyName: 'Avon',
  },
  {
    source: 'https://www.sharefable.com/companies/PropertyAutomate.svg',
    companyName: 'Property Automate',
  },
  {
    source: 'https://www.sharefable.com/companies/burzt-logo.svg',
    companyName: 'Burzt',
  },
];

export const SCREEN_DIFFS_SUPPORTED_VERSION = '2023-07-27';
export const FABLE_IFRAME_GENERIC_CLASSNAME = 'fable-root-gen-frame';
export const FABLE_LEAD_FORM_ID = 'fable-lead-form';
export const FABLE_LEAD_FORM_VALIDATION_FN = 'fable-x-f-vfn';
export const SCREEN_EDITOR_ID = 'fable-ann-editorial-modal';
export const FABLE_LEAD_FORM_FIELD_NAME = 'fable-lead-form-field-name';
export const TOP_LOADER_DURATION = 4;
export const SCREEN_SIZE_MSG = 'screen-size-data';
export const IFRAME_BASE_URL = 'embed';
export const LIVE_BASE_URL = 'live';
export const PREVIEW_BASE_URL = 'preview';
export const HEADER_CTA = 'header_cta';
export const FABLE_LOCAL_STORAGE_ORG_ID_KEY = 'fable/oid';

export enum AMPLITUDE_EVENTS {
    USER_SIGNUP = 'user_signup',
    USER_ORG_ASSIGN = 'user_org_assign',
    TIME_TO_CREATE_TOUR = 'time_to_create_tour',
    CREATE_NEW_TOUR = 'create_new_tour',
    ADD_SCREENS_TO_TOUR = 'add_screens_to_tour',
    SUGGESTED_COLOR_THEME_CHOOSEN='suggested_color_theme_choosen',
    DEFAULT_COLOR_THEME_CHOOSEN='default_color_theme_choosen',
    TOUR_PREVIEW_CLICKED='tour_preview_clicked',
    NEW_ANNOTATION_CREATED='new_annotation_created',
    GENERAL_TOUR_ACTIONS='general_tour_actions',
    EMBED_TOUR='embed_tour',
    EDGE_CONNECTION_DELETED='edge_connection_deleted',
    ANNOTATION_MOVED='annotation_moved',
    API_KEY_GENERATED = 'api_key_generated',
    ANNOTATION_DELETED='annotation_deleted',
    ADVANCED_EL_PICKER_USED='advanced_el_picker_used',
    SCREEN_TAB_SELECTED='screen_tab_selected',
    SCREEN_EDITED='screen_edited',
    ANNOTATION_EDITED='annotation_edited',
    ANNOTATION_APPLY_ALL='annotation_apply_all',
    DEMO_PUBLISHED='demo_published',
    SIDE_PANEL_TAB_CLICKED='side_panel_tab_clicked',
    SHARE_MODAL_OPEN='share_modal_open',
    OPEN_CTA_LINK='open_cta_link',
    CTA_LINK_SITE_CONFIG_CHANGED='cta_link_site_config_changed',
    REMOVE_WATERMARK='remove_watermark',
    SHARE_MODAL_SECTION_CLICKED_EMBED_DEMO='share_modal_section_clicked_embed_demo',
    SHARE_MODAL_SECTION_CLICKED_CTA_SHARE='share_modal_section_clicked_cta_share',
    SHARE_MODAL_SECTION_CLICKED_INTERNAL_SHARE='share_modal_section_clicked_internal_share',
    SAMPLE_DEMO_CLICKED='sample_demo_clicked',
    OPEN_MOBILE_RESPONSIVENESS_DRAWER='open_mobile_responsiveness_drawer',
    MOBILE_RESPONSIVENESS_SELECT_RADIO='mobile_responsiveness_select_radio',
    MOBILE_RESPONSIVENESS_CHANGE='mobile_responsiveness_change',
    DEVICE_MODE_CHANGED='device_mode_changed',
    RESELECT_ELEMENT='reselect_element',
    INTEGRATION_MODAL_OPENED='integration_modal_opened',
    SCROLL_ADJUSTMENT_CHANGED='scroll_adjustment_changed',
}
